import React from "react";

import FacebookLogo from "../images/social-icons/facebook.svg";
import InstagramLogo from "../images/social-icons/instagram.svg";
import LinkedInLogo from "../images/social-icons/linkedin.svg";

export const facebookUrl = "https://www.facebook.com/stichtingjustitia";
export const instagramUrl = "https://www.instagram.com/stichtingjustitia";
export const linkedInUrl =
  "https://www.linkedin.com/company/stichting-justitia";

const IconComponent = ({
  name,
  ...props
}: {
  name: string;
  [key: string]: any;
}) => {
  switch (name) {
    case "facebook":
      return <FacebookLogo {...props} />;
    case "instagram":
      return <InstagramLogo {...props} />;
    case "linkedin":
      return <LinkedInLogo {...props} />;
    default:
      return null;
  }
};

type SocialButtonProps = {
  iconSize: number;
  link: string;
  icon: string;
};

export class SocialButton extends React.Component<SocialButtonProps> {
  render() {
    return (
      <a
        href={this.props.link}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:opacity-70 hover:scale-105 active:opacity-50 active:scale-95 transition-all"
      >
        <IconComponent
          name={this.props.icon}
          width={this.props.iconSize}
          height={this.props.iconSize}
          className="fill-current active:opacity-50 transition-all"
        />
      </a>
    );
  }
}

type SocialButtonsProps = {
  iconSize: number;
};

export class SocialButtons extends React.Component<SocialButtonsProps> {
  render() {
    return (
      <div className="flex gap-4 items-center justify-center">
        <SocialButton
          iconSize={this.props.iconSize}
          link={linkedInUrl}
          icon="linkedin"
        />
        <SocialButton
          iconSize={this.props.iconSize}
          link={instagramUrl}
          icon="instagram"
        />
        {/* <SocialButton
          iconSize={this.props.iconSize}
          link={facebookUrl}
          icon="facebook"
        /> */}
      </div>
    );
  }
}

export { FacebookLogo, InstagramLogo, LinkedInLogo };
export default SocialButtons;
