import React from 'react';
import Link from './Link';
import OneZeroCard from './OneZeroCard';
import SocialCard from './SocialCard';
import SponsorsCard from './SponsorsCard';

import { withPrefix } from 'gatsby';

export class Footer extends React.Component {
  render() {
    return (
      <footer
        className="flex flex-col items-stretch text-white"
        style={{
          backgroundImage: withPrefix('../images/background.jpg'),
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 60"
          width="auto"
        >
          <path
            className="text-white dark:text-slate-900 fill-current scale-x-105"
            fillOpacity={1}
            d="M0,5L1440,60L1440,0L0,0Z"
          ></path>
        </svg>
        <div className="flex flex-col items-center sm:gap-12 gap-6 sm:p-12 p-6 w-full">
          <div className="flex sm:flex-row flex-col items-center sm:gap-16 gap-8">
            <SponsorsCard />
            <div className="flex flex-col items-start gap-4">
              <SocialCard />
              <OneZeroCard />
            </div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row gap-4 w-full items-start justify-between">
            <p className="text-white tracking-wider text-sm font-light bg-blend-color-dodge text-opacity-40">
              Copyright © {new Date().getFullYear()} OneZero Company, all rights
              reserved.
            </p>
            <div className="flex flex-col sm:flex-row sm:gap-6 gap-2 w-full sm:w-auto flex-none">
              <Link to="/legal/privacy">
                <p className="transition-opacity hover:opacity-80 active:opacity-60">
                  Privacy
                </p>
              </Link>
              <Link to="/legal/algemene-voorwaarden">
                <p className="transition-opacity hover:opacity-80 active:opacity-60">
                  Algemene Voorwaarden
                </p>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
