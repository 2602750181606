import { navigate } from 'gatsby';
import React from 'react';

export interface LinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export default class JustitiaLink extends React.Component<LinkProps> {
  render() {
    return (
      <div
        className={`flex-none cursor-pointer ${this.props.className}`}
        onClick={() => {
          // fade out the page
          document.getElementsByClassName('page')[0].classList.add('opacity-0');

          // navigate to the new page using gatsby api (gatsby-link)
          setTimeout(() => {
            navigate(this.props.to);
            setTimeout(() => {
              document
                .getElementsByClassName('page')[0]
                .classList.remove('opacity-0');
            }, 150);
          }, 350);

          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}
