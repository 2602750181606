import './src/styles/global.css';
import React from 'react';
import Layout from './src/components/Layout';

export function wrapPageElement({
  element,
  props,
}: {
  element: React.ReactNode;
  props: any;
}) {
  const markdown =
    props.pageContext?.frontmatter !== undefined &&
    props.pageContext?.frontmatter?.markdownStyling !== false;
  return (
    <Layout {...props} markdown={markdown}>
      {element}
    </Layout>
  );
}
