import React from 'react';

type BalancingCardProps = {
  children: React.ReactNode;
  amount?: number;
};

export default class BalancingCard extends React.Component<BalancingCardProps> {
  state = {
    x: 0,
    y: 0,
  };

  ref = React.createRef<HTMLDivElement>();

  render() {
    return (
      <div
        ref={this.ref}
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d',
        }}
        onMouseMove={(e) => {
          const element = this.ref.current as HTMLElement;
          if (element) {
            // Get the bounding box of the element
            const rect = element.getBoundingClientRect();
            // Get the mouse position inside
            const position = {
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            };
            // Get the center of the element
            const center = {
              x: rect.width / 2,
              y: rect.height / 2,
            };
            // Get the distance from the center
            const delta = {
              x: position.x - center.x,
              y: position.y - center.y,
            };
            // Get the percentage of the distance from the center
            const percentage = {
              x: (delta.x / center.x) * 100,
              y: (delta.y / center.y) * 100,
            };
            // Set the state
            this.setState({
              x: percentage.x * (this.props.amount ?? 0.05),
              y: percentage.y * (this.props.amount ?? 0.05),
            });
          }
        }}
        onMouseLeave={() => {
          this.setState({
            x: 0,
            y: 0,
          });
        }}
      >
        <div
          className="balancing-card transition-transform duration-150"
          style={{
            transform: `rotateX(${-this.state.y}deg) rotateY(${
              this.state.x
            }deg)`,
            pointerEvents: 'none', // Add this line
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
