import React from "react";
import BalancingCard from "./BalancingCard";
import SocialButtons from "./SocialButtons";

export class SocialCard extends React.Component {
  render() {
    return (
      <div className="flex flex-col gap-4 text-white">
        <h2 className="text-sm font-light text-white text-opacity-40">
          Volg ons op social media
        </h2>
        <SocialButtons iconSize={32} />
      </div>
    );
  }
}

export default SocialCard;
