import React from "react";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import Header from "./Header";
import { graphql, StaticQuery } from "gatsby";

// Current year query
const currentYearQuery = graphql`
  query CurrentYearQuery {
    currentYear {
      year
      color
      schedule {
        link
        title
      }
    }
  }
`;

type LayoutProps = {
  children: React.ReactNode;
  markdown: boolean;
};

export default class Layout extends React.Component<LayoutProps> {
  state = {
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    return (
      <StaticQuery
        query={currentYearQuery}
        render={(data) => (
          <div
            className={`${
              this.state.loaded ? "" : "opacity-0"
            } page min-h-full min-w-full transition-opacity duration-300`}
          >
            <TopMenu
              color="#1f0c35"
              currentYear={data.currentYear?.year}
              mootcourt={
                data.currentYear?.schedule?.find((item: { title: string }) =>
                  item.title.toLowerCase().includes("pleitwedstrijd")
                )?.link ?? ""
              }
              seminar={
                data.currentYear?.schedule?.find((item: { title: string }) =>
                  item.title.toLowerCase().includes("eindfeest")
                )?.link ?? ""
              }
            />
            <Header />
            <div className="bg-white dark:bg-slate-900 -mt-1 -mb-1 flex flex-col items-center w-full z-30">
              <div
                className={`w-full text-slate-800 dark:text-slate-300 page-content ${
                  this.props.markdown ? "markdown" : ""
                }`}
              >
                {this.props.children}
              </div>
            </div>
            <Footer />
          </div>
        )}
      />
    );
  }
}
