import React from "react";

import OneZeroLogo from "../images/onezero-logo.svg";

export class OneZeroCard extends React.Component {
  render() {
    return (
      <div className="flex w-full flex-col items-center gap-4 rounded-xl p-4">
        <h2 className="text-sm font-light text-white text-opacity-40">
          Deze website is <br />
          ontwikkeld door
        </h2>
        <a
          href="https://onezero.company"
          target="_blank"
          rel="noreferrer"
          className="transition-opacity hover:opacity-70 active:opacity-40"
        >
          <OneZeroLogo width="auto" height="40px" />
        </a>
      </div>
    );
  }
}

export default OneZeroCard;
