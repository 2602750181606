import React from "react";
import { SocialButtons } from "./SocialButtons";

// Assets
import Logo from "../images/justitia-logo.svg";

export class Header extends React.Component {
  render() {
    return (
      <header className="relative z-10 flex flex-col justify-end items-center text-white lightbox-hide menu-hide transition-opacity bg-primary">
        <div className={`transition-all absolute z-10 p-2 m-4 top-0 right-0`}>
          <SocialButtons iconSize={32} />
        </div>
        <div
          className={`relative transition-all pointer-events-none z-10 flex flex-col items-center justify-center`}
        >
          <Logo className="w-36 max-w-full h-20" />
        </div>
      </header>
    );
  }
}

export default Header;
