import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import BalancingCard from "./BalancingCard";
import Link from "./Link";

const sponsorsQuery = graphql`
  query SponsorQuery {
    allCompanies(filter: { sponsor: { eq: true } }) {
      nodes {
        identifier
        name
        website
        logo {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              height: 48
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    currentYear {
      year
    }
  }
`;

export default class SponsorsCard extends React.Component {
  render() {
    return (
      <StaticQuery
        query={sponsorsQuery}
        render={(data) => (
          <Link to="/sponsoren">
            <BalancingCard>
              <div className="bg-white bg-opacity-8 rounded-xl max-w-xs flex flex-col gap-4">
                <div className="p-6 flex flex-col gap-4">
                  <p className="text-2xl font-bold text-primary">Sponsoren</p>
                  {/* <p className="text-lg text-primary leading-tight">
                    Interesse in het sponsoren van Justitia{" "}
                    {data.currentYear.year}?
                  </p> */}
                </div>
                <div className="relative w-full overflow-x-hidden h-12">
                  <div className="h-12 absolute top-0 left-0 flex flex-row gap-8 animate-marquee px-6">
                    {data.allCompanies.nodes.map((node: any) => {
                      return (
                        <GatsbyImage
                          image={node.logo.childImageSharp.gatsbyImageData}
                          alt={"Logo van " + node.name}
                          draggable={false}
                          objectFit="scale-down"
                          key={node.identifier + "1"}
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="h-12 absolute top-0 left-0 flex flex-row gap-8 animate-marquee2 px-6">
                    {data.allCompanies.nodes.map((node: any) => {
                      return (
                        <GatsbyImage
                          image={node.logo.childImageSharp.gatsbyImageData}
                          alt={"Logo van " + node.name}
                          draggable={false}
                          objectFit="scale-down"
                          key={node.identifier + "2"}
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
                <p className="p-6 text-center text-sm text-primary text-opacity-60">
                  Klik hier voor meer informatie
                </p>
              </div>
            </BalancingCard>
          </Link>
        )}
      />
    );
  }
}
